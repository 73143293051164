import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AlertService } from 'src/app/commons/services/alert.service';
import { LaravelMessageService } from 'src/app/commons/services/backend/laravel-message.service';
import { MessageModalComponent } from 'src/app/modules/home/messages/message-modal/message-modal.component';
import { SelectDateModalComponent } from 'src/app/modules/home/messages/select-date-modal/select-date-modal.component';

import * as MessageActions from '../actions/message.actions';
import * as RouterActions from '../actions/router.actions';
import { AppState } from '../reducers';
import * as ClientSelectors from '../selectors/client.selectors';
import * as MessageSelectors from '../selectors/message.selectors';

@Injectable()
export class MessageEffects {

  error$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.cancelMessagesFailed, MessageActions.sendSingleMessageFailed, MessageActions.exportCsvFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore:', error);
        }
      })
    )
  }, { dispatch: false }
  );


  loadMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.loadMessages),
      switchMap(({ templateId, page, perPage, order, direction, filters }) => {
        return this.messageService.list(templateId, page, perPage, order, direction, filters)
          .pipe(
            map(result =>
              MessageActions.loadMessagesCompleted({ messages: result.data, currentPage: page, total: result.total, perPage, order, direction, filters })
            ),
            catchError(error => {
              return of(MessageActions.loadMessagesFailed({ error }))
            })
          )
      })
    )
  }
  );

  loadMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.loadMessage),
      switchMap(({ id }) => {
        return this.messageService.show(id)
          .pipe(
            map(result =>
              MessageActions.loadMessageCompleted({ message: result })
            ),
            catchError(error => {
              return of(MessageActions.loadMessageFailed({ error }))
            })
          )
      })
    )
  }
  );

  changePage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.changePage),
      concatLatestFrom(() => this.store$.select(MessageSelectors.getMessagesTableState)),
      map(([{ page, size }, { templateId, total, currentPage, perPage, direction, order, filters, }]) => MessageActions.loadMessages({ templateId, page, perPage: size, order, direction, filters }))
    )
  }
  );

  changeSort$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.changeSort),
      concatLatestFrom(() => this.store$.select(MessageSelectors.getMessagesTableState)),
      map(([action, { templateId, total, currentPage, perPage, direction, order, filters }]) => MessageActions.loadMessages({ templateId, page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters }))
    )
  }
  );

  changeFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.changeFilters),
      concatLatestFrom(() => this.store$.select(MessageSelectors.getMessagesTableState)),
      map(([{ filters }, { templateId, total, currentPage, perPage, direction, order }]) => MessageActions.loadMessages({ templateId, page: currentPage, perPage, order, direction, filters }))
    )
  }
  );

  selectMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.selectMessage),
      map(({ message }) => {
        let dialogRef = this.dialog.open(MessageModalComponent, {
          data: {
            message
          }
        });
        return MessageActions.messageDialogOpened({ dialogId: dialogRef.id });
      }))
  }
  );

  selectNewDate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.selectNewDate),
      map(({ message }) => {
        let dialogRef = this.dialog.open(SelectDateModalComponent, {
          data: {
            message
          }
        });
        return MessageActions.messageDialogOpened({ dialogId: dialogRef.id });
      }))
  }
  );

  rescheduleMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.rescheduleMessage),
      switchMap(({ message, date }) =>
        this.messageService.reschedule(message.id, date)
          .pipe(
            map(result =>
              MessageActions.rescheduleMessageCompleted({ message: result })
            ),
            catchError(error => of(MessageActions.rescheduleMessageFailed({ error })))
          )
      )
    )
  }
  );

  onSaveCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.rescheduleMessageCompleted),
      tap(() => this.alertService.showConfirmMessage(`Messaggio ri-pianificato con successo`)),
      map(() => MessageActions.closeMessageDialog())
    )
  }
  );

  closeDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.closeMessageDialog),
      concatLatestFrom(() => this.store$.select(MessageSelectors.getMessageDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    )
  }, { dispatch: false }
  );

  cancelMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.cancelMessage),
      switchMap(({ message }) =>
        this.alertService.showConfirmDialog('Conferma annullamento messaggio', `Sei sicuro di voler annullare il messaggio per ${message.phone}? <br/><br/>Questa azione non può essere annullata.`)
          .pipe(
            filter((confirm) => !!confirm),
            switchMap(() => this.messageService.cancel(message.id).pipe(
              map(result => MessageActions.cancelCompleted(result)),
              catchError(error => of(MessageActions.cancelFailed({ error })))
            ))
          )
      )
    )
  }
  );

  cancelSequence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.cancelSequence),
      switchMap(({ message }) =>
        this.alertService.showConfirmDialog('Conferma annullamento sequenza', `Sei sicuro di voler eliminare l'intera sequenza per ${message.phone}? <br/><br/>Questa azione non può essere annullata.`)
          .pipe(
            filter((confirm) => !!confirm),
            switchMap(() => this.messageService.cancel(message.id, true).pipe(
              map(result => MessageActions.cancelCompleted(result)),
              catchError(error => of(MessageActions.cancelFailed({ error })))
            ))
          )
      )
    )
  }
  );

  onDeleteCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.cancelCompleted),
      tap(({ count }) => this.alertService.showConfirmMessage(`${count || 0} messaggi annullati`)),
    )
  }, { dispatch: false }
  );

  reloadAfterDeleteOrSave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.cancelCompleted, MessageActions.rescheduleMessageCompleted, MessageActions.rescheduleMessagesCompleted, MessageActions.cancelMessagesCompleted),
      concatLatestFrom(() => this.store$.select(MessageSelectors.getMessagesTableState)),
      map(([_, { currentPage, perPage, direction, order, filters }]) => MessageActions.loadMessages({ page: currentPage, perPage, order, direction, filters })),
    )
  }
  );

  selectNewDateMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.selectNewDateMessages),
      map(({ messages }) => {
        let dialogRef = this.dialog.open(SelectDateModalComponent, {
          data: {
            messages,
          }
        });
        return MessageActions.messageDialogOpened({ dialogId: dialogRef.id });
      }))
  }
  );

  rescheduleMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.rescheduleMessages),
      switchMap(({ messages, date }) =>
        this.messageService.reschedules(messages, date)
          .pipe(
            map(result =>
              MessageActions.rescheduleMessagesCompleted(result)
            ),
            catchError(error => of(MessageActions.rescheduleMessagesFailed({ error })))
          )
      )
    )
  }
  );

  onRescheduleMessagesCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.rescheduleMessagesCompleted),
      tap((count) => this.alertService.showConfirmMessage(`${count || 0} messaggi ri-pianificato con successo`)),
      map(() => MessageActions.closeMessageDialog())
    )
  }
  );

  cancelMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.cancelMessages),
      switchMap(({ messages }) =>
        this.alertService.showConfirmDialog('Conferma annullamento messaggi', `Sei sicuro di voler annullare i messaggi selezionati? <br/><br/>Questa azione non può essere annullata.`)
          .pipe(
            filter((confirm) => !!confirm),
            switchMap(() => this.messageService.cancels(messages).pipe(
              map(result => MessageActions.cancelCompleted(result)),
              catchError(error => of(MessageActions.cancelFailed({ error })))
            ))
          )
      )
    )
  }
  );

  sendSingleMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.sendSingleMessage),
      filter(({ message }) => !!message),
      withLatestFrom(this.store$.select(ClientSelectors.getSession)),
      filter(([, session]) => !!session),

      switchMap(([{ message, close }, session]) =>
        this.alertService.showConfirmDialog('Conferma invio messaggio', `Sei sicuro di voler ${!!message.date ? 'programmare' : 'inviare'}  il messaggio a ${message.phone}?`)
          .pipe(
            filter((confirm) => !!confirm),
            switchMap(() => this.messageService.sendSingle(message, session.id).pipe(
              map(messageId => MessageActions.sendSingleMessageCompleted({ messageId, delay: !!message.date, close })),
              catchError(error => of(MessageActions.sendSingleMessageFailed({ error })))
            ))
          )))
  })

  sendSingleMessageCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.sendSingleMessageCompleted),
      tap(({ delay }) => this.alertService.showConfirmMessage(`Messaggio ${delay ? 'programmato' : 'inviato'} correttamente`)),
      filter(({ close }) => !!close),
      map(() => RouterActions.routerBack())
    )
  })

  exportMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessageActions.exportCsv),
      switchMap(({ templateId, filters }) =>
        this.messageService.export(templateId, filters).pipe(
          map((result) =>
            MessageActions.exportCsvCompleted({
              blob: new Blob([result], { type: 'text/csv' }),
            })
          ),
          tap(() =>
            this.alertService.showConfirmMessage(
              `Esportazione storico messaggi generata con successo`
            )
          ),
          catchError((error) =>
            of(MessageActions.exportCsvFailed({ error }))
          )
        )
      )
    )
  );

  downloadCsv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MessageActions.exportCsvCompleted),
        tap(({ blob }) => {
          saveAs(blob, `storico_messaggi.csv`);
        })
      ),
    { dispatch: false }
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private messageService: LaravelMessageService,
    private alertService: AlertService
  ) { }
}
